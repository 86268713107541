<template>
  <div>
    <v-row class="pt-4">
      <v-col cols="9">
        <v-data-table
          :hide-default-footer="true"
          :items="[record]"
          :headers="headerTable1"
          class="table-1"
          :no-data-text="$t('rules.noData')"
        >
          <template v-slot:[`item.FacilityName`]="{}">
            <span>{{ facilityName }}</span>
          </template>
          <template v-slot:[`item.TotalPrice`]="{ item }">
            <span>{{ getTotalPrice(item.vmStayRights) }}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="3">
        <v-btn
          v-if="!isTentative"
          class="charge-breakdown-display-btn float-right"
          @click="priceDetailDialog = true"
          >料金内訳表示</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :hide-default-footer="true"
      :items="record.bookingGroup.bookings"
      :headers="headerTable2"
      :no-data-text="$t('rules.noData')"
      class="table-2"
    >
      <template v-slot:[`item.CheckInDate`]="{ item }">
        <span> {{ item.checkInDate | formatDate }}</span>
      </template>
      <template v-slot:[`item.CheckOutDate`]="{ item }">
        <span> {{ item.checkOutDate | formatDate }}</span>
      </template>
      <template v-slot:[`item.CheckPeriodDate`]="{ item }">
        <span>
          {{ getCheckPeriodDate(item.checkInDate, item.checkOutDate) }}</span
        >
      </template>
      <template v-slot:[`item.UsageConfirmation`]="{ item }">
        <v-btn
          class="usage-confirm-button"
          :class="{confirmed: !!item.stayConfirmedAt}"
          @click="changeUseUnconfirmed(item, !item.stayConfirmedAt)"
          :disabled="!checkbtnCancel(item.computed.status) || !checkPerUser"
        >
          {{ item.stayConfirmedAt ? '利用確認済み' : '利用未確認' }}
        </v-btn>
      </template>
      <template v-slot:[`item.ReservationConfirmationWithPMS`]="{ item }">
        <span>
          <v-btn
            class="reservation-confirmation-with-pms-btn"
            color="#FFFFFF"
            @click="redirectToContractDetailPMS(item)"
            >PMSで予約確認</v-btn
          ></span
        >
      </template>
      <template v-slot:[`item.CancelAndConvertPoints`]="{ item }">
        <span>
          <v-btn
            class="cancel-and-convert-points-btn"
            color="#DB3069"
            @click="startCancel(item)"
            :disabled="!checkbtnCancel(item.computed.status) || !checkPerUser"
            >キャンセルしてポイント変換</v-btn
          >
        </span>
      </template>
    </v-data-table>
    <PriceBreakdownDialog
      :vmStayRights="record.vmStayRights"
      :visible="priceDetailDialog"
      @close="priceDetailDialog = false"
    />
    <calculateBookingCancelPenalty
      :visible="cancelDialog"
      :id="cancellingBooking ? cancellingBooking.id : null"
      :Checkopen="cancelDialog"
      :totalSpPoint="null"
      @close="endCancel"
      @requestReload="$reloadRoute"
    />
    <ContractDialog
      :visible="confirmBookingDialog"
      @close="confirmBookingDialog = false"
      className="custom-dialog"
      :width="180"
    >
      <template v-slot:content>
        <div class="align-center">
          <v-select
            :items="confirmSelectItems"
            :value="!toConfirmBooking"
            item-text="text"
            item-value="value"
            style="height: 40px"
            @change="changeSelected"
          />
        </div>
      </template>
    </ContractDialog>
  </div>
</template>

<script>
import calculateBookingCancelPenalty from '../Sp_Ep/UsageSituation/CalculateBookingCancelPenalty.vue';
import PriceBreakdownDialog from './PriceBreakdownDialog.vue';
import { LINK_PMS } from '@/constants/linkPMS.js';
import moment from 'moment';
import ContractDialog from '@/components/dialog/ContractDialog.vue';
import { convertCurrency } from '@/constants/functions.js';
import { checkPermissionUserCurrent } from '@/utils/permissions';

export default {
  components: { PriceBreakdownDialog, calculateBookingCancelPenalty, ContractDialog },
  props: {
    record: { Object },
    isTentative: {Boolean},
    facilityName: {String}
  },
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      priceDetailDialog: false,
      cancelDialog: false,
      cancellingBooking: {},
      bookingToConfirm: {},
      toConfirmBooking: false,
      confirmBookingDialog: false,
    };
  },
  computed: {
    headerTable1() {
      const headers = [
        {
          text: '年',
          value: 'year',
          sortable: false,
          width: '60px',
        },
        {
          text: '権利施設',
          value: 'FacilityName',
          sortable: false,
          width: '200px',
        },
      ];
      if (!this.isTentative) {
        headers.push({
          text: '金額',
          value: 'TotalPrice',
          sortable: false,
          width: '130px',
        });
      }

      return headers;
    },
    headerTable2() {
      const headers = [
        {
          text: 'ステータス',
          value: 'computed.status',
          sortable: false,
          width: '120px',
        },
        {
          text: '予約ID',
          value: 'id',
          sortable: false,
          width: '100px',
        },
        {
          text: '施設',
          value: 'room.roomType.facility.name',
          sortable: false,
          width: '250px',
        },
        {
          text: '部屋タイプ',
          value: 'room.roomType.name',
          sortable: false,
          width: '150px',
        },
        {
          text: 'チェックイン日',
          value: 'CheckInDate',
          sortable: false,
          width: '160px',
        },
        {
          text: 'チェックアウト日',
          value: 'CheckOutDate',
          sortable: false,
          width: '160px',
        },
        {
          text: '泊数',
          value: 'CheckPeriodDate',
          sortable: false,
          width: '70px',
        },
        this.isTentative
          ? null
          : { text: '利用確認', value: 'UsageConfirmation', sortable: false },
        {
          text: '',
          value: 'ReservationConfirmationWithPMS',
          sortable: false,
          align: 'end',
          width: '100px',
        },
        this.isTentative
          ? null
          : {
              text: '',
              value: 'CancelAndConvertPoints',
              sortable: false,
              align: 'end',
              width: '100px',
            },
      ];

      return headers.filter((v) => !!v);
    },
    confirmSelectItems() {
        return [
        { text: '利用未確認', value: false },
        { text: '利用確認済み', value: true },
      ]
    }
  },
  methods: {
    getCurrency(price) {
      return convertCurrency(price);
    },
    getTotalPrice(vmStayRights) {
      let totalPrice = 0;
      if (vmStayRights) {
        for (let index = 0; index < vmStayRights.length; index++) {
          totalPrice += vmStayRights[index].price;
        }
        return '¥' + this.getCurrency(totalPrice);
      } else {
        return null;
      }
    },
    checkbtnCancel(e) {
      if (e === 'キャンセル') {
        return false;
      } else {
        return true;
      }
    },
    startCancel(item) {
      this.cancellingBooking = item;
      this.cancelDialog = true;
    },
    endCancel() {
      this.cancelDialog = false;
      this.cancellingBooking = null;
    },
    requestReload() {
      this.$emit('needsReload', { record: this.record });
    },
    redirectToContractDetailPMS(item) {
      const url = `${LINK_PMS.linkContractDetail}?id=${
        item.id
      }&tab=basic-information&fromPage=crm`;
      window.open(url);
    },
    changeUseUnconfirmed(booking, toConfirm) {
      this.bookingToConfirm = booking;
      this.toConfirmBooking = toConfirm;
      this.confirmBookingDialog = true;
    },
    async changeSelected(value) {
      this.isShowContractDialog = false;
      const data = {
        id: this.bookingToConfirm.id,
        undo: !value,
      };
      this.bookingToConfirm = null;
      await this.$store.dispatch('UpdatepwContractUsageList', data).then(() => {
        this.requestReload();
      });
    },
    getCheckPeriodDate(checkInDate, checkOutDate) {
      if (checkInDate && checkOutDate) {
        return moment(checkOutDate).diff(moment(checkInDate), 'days') + '泊';
      } else {
        return null;
      }
    },
  },
  watch : {
    cancelDialog: console.log
  }
};
</script>

<style lang="scss" scoped>
.usage-confirm-button {
  background: #f5511e 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  color: white;
  &.confirmed {
    opacity: 1;
    background-color: #ffc3b1 !important;;
  } 
}

.table-1 {
  width: 400px;
  ::v-deep {
    table {
      thead {
        tr {
          th {
            border-bottom: none !important;
            color: #424242 !important;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      tbody {
        tr {
          td {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;

            &:nth-child(2) {
              max-width: 80px;
            }
            &:nth-child(3) {
              max-width: 80px;
            }
          }
        }
      }
    }
  }
}
.table-2 {
  ::v-deep {
    table {
      padding-bottom: 2rem !important;
      thead {
        tr {
          th {
            color: #424242 !important;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 16px;
            border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
            color: #424242;
            font-weight: 400;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            &:nth-child(1) {
              color: #db3069 !important;
              font-weight: bold;
              max-width: 80px;
            }
            &:nth-child(3) {
              max-width: 80px;
            }
            &:nth-child(4) {
              max-width: 80px;
            }
          }
        }
      }
    }
  }
}

.charge-breakdown-display-btn {
  border: 0.5px solid var(--pms_primaryr_1873d9) !important;
  background: #ffffff !important;
  font-size: 12px;
  color: #1873d9;
  ::v-deep {
    span {
      font-weight: 600 !important;
    }
  }
}

.usage-confirmation-btn {
  color: #ffffff;
  font-size: 16px;
}

.reservation-confirmation-with-pms-btn {
  border: 0.5px solid var(--pms_primaryr_1873d9) !important;
  background: #ffffff !important;
  font-size: 12px;
  color: #1873d9;
  ::v-deep {
    span {
      font-weight: 600 !important;
    }
  }
}

.cancel-and-convert-points-btn {
  color: #ffffff;
  font-size: 14px;
}

</style>