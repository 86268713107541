<template>
  <v-dialog v-model="dialog" max-width="300">
    <v-card>
      <v-card-title>
        <h4 class="header-title">料金内訳</h4>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3" class="pb-0">
            <p class="lable">日数</p>
            <p class="value">{{ vmStayRights.length }}日間</p>
          </v-col>
          <v-col cols="9" class="pb-0">
            <p class="lable">合計料金</p>
            <p class="value">{{ getTotalPrice() }}</p>
          </v-col>
        </v-row>
        <v-row v-for="(item, index) in vmStayRights" :key="index">
          <v-col cols="6" class="pb-0">
            <p class="lable">{{ index + 1 }}泊目</p>
            <p class="value">{{ formatDate(item.stayDate) }}</p>
          </v-col>
          <v-col cols="6" class="pb-0">
            <p class="lable">{{ index + 1 }}泊目料金</p>
            <p class="value">¥{{ getCurrency(item.price) }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        ><v-spacer></v-spacer>

        <v-btn class="button-close" text @click="closeDialog">
          <v-icon size="20">mdi-close</v-icon>
          <span>&emsp;閉じる</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { convertCurrency } from '@/constants/functions.js';

export default {
  name: 'PriceBreakdownDialog',
  props: {
    visible: Boolean,
    vmStayRights: Array,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD');
    },
    getCurrency(price) {
      return convertCurrency(price);
    },
    getTotalPrice() {
      var totalPrice = 0;
      if (this.vmStayRights) {
        for (let index = 0; index < this.vmStayRights.length; index++) {
          totalPrice += this.vmStayRights[index].price;
        }
        return '¥' + this.getCurrency(totalPrice);
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 28px;
  font-weight: bold;
  color: #212121;
}
.lable {
  font-weight: 600;
  color: #000000;
  font-size: 9px;
  margin-bottom: 0;
}
.value {
  color: #444444;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.button-close {
  color: #ffffff;
  background-color: #c92d2d;
}
</style>
