<template>
  <div v-if="!loading">
    <div>
      <!-- Title -->
      <div class="py-5 px-10 header-main d-flex justify-space-between">
        <div class="d-flex justify-space-between w-100">
          <h1 class="page-title-main d-flex justify-space-between">
            {{clientName}}:
            <div>
              <slot>{{ $route.params.type }}契約</slot>
              <v-btn small class="btn-crm-primary mx-2 btn-cus" @click="openCustomerDetail"> 会員情報を開く </v-btn>
            </div>
          </h1>

          <div class="d-flex justify-end">
            <slot name="cancel"></slot>
            <slot name="send"></slot>
          </div>
        </div>
        <slot name="btn-right"></slot>
      </div>
      <!-- <contract-detail-tab></contract-detail-tab> -->
      <ContractDetailTabMW />
      <!-- End Title -->

      <!-- tentative === false  -->
      <div v-if="tentative === false">
        <div class="pt-8 px-8">
          <v-btn
            class="t-btn--prm"
            :disabled="contractStatus !== '有効'"
            @click="redirectPMS"
            >MO予約作成</v-btn
          >
          <div class="float-right">
            <v-btn class="t-btn--red-dark" @click="$windowClose">
              <v-icon>mdi-close</v-icon>
              <span>&emsp;閉じる</span>
            </v-btn>
            <v-btn :disabled="!checkPerUser" class="t-btn--prm ml-3">
              <!-- @click="actionUpdate()" -->
              保存
            </v-btn>
          </div>
        </div>
        <h2
          class="mt-6 ml-8"
          style="font-size: 20px; font-weight: bold; color: #004F8B;"
        >
          利用状況
        </h2>
        <VMUsageTable :contractUsage="contractUsage" @needsReload="reloadData" />
      </div>
      <!-- End tentative === false -->

      <!-- tentative === true  -->
      <div v-else>
        <div class="mx-10 mt-3 d-flex justify-space-between">
          <div>
            <template>
              <div class="d-flex justify-space-between pa-2 div-alert">
                <div class="mt-1">
                  <i class="icon-alert fas fa-exclamation-triangle"></i>
                  <span
                    class="ml-2 text-alert"
                    style="font-size: 16px !important;"
                    >この契約は仮押さえ中です</span
                  >
                </div>
                <div>
                  <v-btn
                    :disabled="!checkPerUser"
                    class="btn-billing btn-crm-primary mr-4"
                    style="font-size: 14px !important;"
                    @click="dialogChirlden = true"
                  >
                    仮契約を削除
                  </v-btn>
                  <v-btn
                    :disabled="!checkPerUser"
                    class="btn-billing btn-crm-primary"
                    style="font-size: 14px !important;"
                    @click="startConfirmedBookingCreate"
                  >
                    本契約に切替
                  </v-btn>
                </div>
              </div>
            </template>
          </div>
          <div>
            <v-btn class="t-btn--red-dark mr-4" @click="closeWindow">
              <v-icon>mdi-close</v-icon>
              {{ $t('task_29786.close') }}
            </v-btn>
            <v-btn :disabled="!checkPerUser" class="t-btn--prm"> 保存 </v-btn>
          </div>
        </div>
        <div class="ml-8 mt-4">
          <v-btn class="t-btn--prm" @click="startTentativeBookingCreate" :disabled="hasBookings || !checkPerUser">
            仮押さえ予約を作成
          </v-btn>
          <v-btn class="t-btn--prm mx-5 btn-cus-disabled" @click="startTentativeBookingUpdate" :disabled="!hasBookings || !checkPerUser">
            仮押さえ予約を編集
          </v-btn>
          <v-btn class="t-btn--prm btn-cus-disabled" @click="deleteTentativeBookings" :disabled="!hasBookings || !checkPerUser">
            仮押さえ予約を削除
          </v-btn>
        </div> 
        <!-- dialog -->
        <CreateContract 
          :visible="visible === 1"
          @close="visible = -1"
          @created="tentativeBookingsCreated" 
          :contractId="contractId"
          :tentative="createTentative"
          :existingContract="contractUsage"
        />
        <v-row justify="center">
          <v-dialog v-model="dialogChirlden" persistent max-width="290">
            <v-card>
              <v-card-title class="text-h5">
                仮契約を削除?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!checkPerUser"
                  class="t-btn--red-dark"
                  text
                  @click="dialogChirlden = false"
                >
                  いいえ
                </v-btn>
                <v-btn
                  class="file t-btn--prm"
                  style="color: white"
                  @click="del()"
                  :disabled="!checkPerUser"
                >
                  はい
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <VMUsageTable :contractUsage="contractUsage" @needsReload="reloadData" />
      </div>
    </div>
  </div>
</template>

<script>
import CreateContract from '../../../customer/response/VMTemporaryContract/CreateContract.vue';
import router from '@/router';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions.js';
import gql from 'graphql-tag';
import ContractDetailTabMW from '@/views/contract/contractDetail/MW/contractDetailTabMW.vue';
import VMUsageTable from './VMUsageTable'

export default {
  name: 'SPUsageStatus',
  components: {
    CreateContract,
    ContractDetailTabMW,
    VMUsageTable,
  },
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dialogChirlden: false,
      visible: -1,
      contractUsage: {},
      loading: true,
      createTentative: true,
    };
  },
  mounted() {
    this.reloadData();
  },
  computed: {
    ...mapGetters([
      'getVmContractUsageList',
      'getListContractUsage'
    ]),
    id () { return this.contractUsage?.id; },
    tentative () { return this.contractUsage?.tentative; },
    contractStatus () { return this.contractUsage?.status; },
    contractId() {return parseInt(this.$route.params.id, 10) },
    clientId () { return this.contractUsage?.clientId; },
    clientName () { return this.contractUsage?.client.name; },
    hasBookings () { return !!this.contractUsage?.rights.length; }
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'VMComtractUsageList',
    ]),
    openCustomerDetail() {
      window.open(`/customer-details/${this.clientId}?typeClient=${this.clientType}`)
    },
    redirectPMS() {
      const url = `${process.env.VUE_APP_URL_PMS}/reservation/calendar?id=${this.clientId}&contractId=${this.contractId}&fromPage=crm`;
      window.open(url);
    },
    ...mapMutations([
      'setCopyErrorText', 
      'setVmContractUsageList', 
      'setDialogSuccess',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setAlertSuccess',
      'setAlertError',
    ]),
    async reloadData() {
      this.loading = true;
      try {
        await this.VMComtractUsageList(parseInt(router.currentRoute.params.id)).then(
          vmContractUsage => {
            this.contractUsage = vmContractUsage;
          },
        );
      } finally {
        this.loading = false
      }
    },
    closeWindow(){
      window.close();
    },
    async del() {
      this.$store.commit('setLoadingOverlayShow', { root: true })
      const query = `
            mutation ($contractId: Int!) {
              deleteTentativeContract(id: $contractId) {
                __typename
              }
            }
          `
      const variables = {
            contractId: this.contractId
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          this.setAlertSuccess('削除しました。')
          this.dialogChirlden = false
          this.$store.commit('setLoadingOverlayHide', { root: true })
          window.close();
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
          this.$store.commit('setLoadingOverlayHide', { root: true })
        });
    },
    async deleteTentativeBookings () {
      this.$store.commit('setLoadingOverlayShow', { root: true })
      const query = `
            mutation (
              $contractId:Int!
            ) {
              deleteVmTentativeBookings(contractId: $contractId)
            }
          `
      const variables = {
            contractId: this.contractId
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then(data => {
          this.setAlertSuccess('仮押さえ予約を削除しました。')
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        });
  
      this.$store.commit('setLoadingOverlayHide', { root: true })
      this.reloadData();
    },
    startTentativeBookingCreate () {
      this.$store.commit('resetVmContractCreate')
      this.startTentativeBookingUpdate()
    },
    startTentativeBookingUpdate() {
      this.createTentative = true;
      this.visible = 1
    },
    startConfirmedBookingCreate () {
      this.createTentative = false;
      this.visible = 1
    },
    tentativeBookingsCreated () {
      this.visible = -1;
      this.reloadData();
    }
  },
};
</script>

<style lang="scss" scoped>
.title-cus {
  color: #C92D2D !important;
}
.btn-cus {
  height: 32px !important;
  ::v-deep {
    .v-btn__content {
      color: #1873D9 !important;
      font-weight: 600 !important;
      font-size: 12px !important;
    }
  }
}
.btn-cus-disabled {
  ::v-deep {
    .v-btn__content {
      color: #ffffff !important;
    }
  }
}

// div
.div-alert {
  color: #ffffff;
  width: 500px;
  margin-left: -6px;
  height: 46px;
  background-color: #cb396c;
  border-radius: 9px;
}
// icon
.icon-alert {
  font-size: 20px;
}
.text-alert {
  font-size: 14px;
}
.btn-billing {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  background-color: #cb396c !important;
  width: 110px;
  font-size: 12px !important;
  height: 32px !important;
}
.custom-dialog {
  label {
    width: 120px !important;
    flex-shrink: 0;
    text-align: right;
  }
}
.pw-custom {
  width: 99px;
  height: 27px;
  background: #ffc3b1 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  color: #ffffff;
}
</style>
