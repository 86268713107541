var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-data-table',{staticClass:"table-1",attrs:{"hide-default-footer":true,"items":[_vm.record],"headers":_vm.headerTable1,"no-data-text":_vm.$t('rules.noData')},scopedSlots:_vm._u([{key:"item.FacilityName",fn:function(ref){return [_c('span',[_vm._v(_vm._s(_vm.facilityName))])]}},{key:"item.TotalPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTotalPrice(item.vmStayRights)))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[(!_vm.isTentative)?_c('v-btn',{staticClass:"charge-breakdown-display-btn float-right",on:{"click":function($event){_vm.priceDetailDialog = true}}},[_vm._v("料金内訳表示")]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"table-2",attrs:{"hide-default-footer":true,"items":_vm.record.bookingGroup.bookings,"headers":_vm.headerTable2,"no-data-text":_vm.$t('rules.noData')},scopedSlots:_vm._u([{key:"item.CheckInDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.checkInDate)))])]}},{key:"item.CheckOutDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.checkOutDate)))])]}},{key:"item.CheckPeriodDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getCheckPeriodDate(item.checkInDate, item.checkOutDate)))])]}},{key:"item.UsageConfirmation",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"usage-confirm-button",class:{confirmed: !!item.stayConfirmedAt},attrs:{"disabled":!_vm.checkbtnCancel(item.computed.status) || !_vm.checkPerUser},on:{"click":function($event){return _vm.changeUseUnconfirmed(item, !item.stayConfirmedAt)}}},[_vm._v(" "+_vm._s(item.stayConfirmedAt ? '利用確認済み' : '利用未確認')+" ")])]}},{key:"item.ReservationConfirmationWithPMS",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-btn',{staticClass:"reservation-confirmation-with-pms-btn",attrs:{"color":"#FFFFFF"},on:{"click":function($event){return _vm.redirectToContractDetailPMS(item)}}},[_vm._v("PMSで予約確認")])],1)]}},{key:"item.CancelAndConvertPoints",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-btn',{staticClass:"cancel-and-convert-points-btn",attrs:{"color":"#DB3069","disabled":!_vm.checkbtnCancel(item.computed.status) || !_vm.checkPerUser},on:{"click":function($event){return _vm.startCancel(item)}}},[_vm._v("キャンセルしてポイント変換")])],1)]}}],null,true)}),_c('PriceBreakdownDialog',{attrs:{"vmStayRights":_vm.record.vmStayRights,"visible":_vm.priceDetailDialog},on:{"close":function($event){_vm.priceDetailDialog = false}}}),_c('calculateBookingCancelPenalty',{attrs:{"visible":_vm.cancelDialog,"id":_vm.cancellingBooking ? _vm.cancellingBooking.id : null,"Checkopen":_vm.cancelDialog,"totalSpPoint":null},on:{"close":_vm.endCancel,"requestReload":_vm.$reloadRoute}}),_c('ContractDialog',{attrs:{"visible":_vm.confirmBookingDialog,"className":"custom-dialog","width":180},on:{"close":function($event){_vm.confirmBookingDialog = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"align-center"},[_c('v-select',{staticStyle:{"height":"40px"},attrs:{"items":_vm.confirmSelectItems,"value":!_vm.toConfirmBooking,"item-text":"text","item-value":"value"},on:{"change":_vm.changeSelected}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }