<template>
  <div>
    <v-card
      class="mt-8 mx-8 pl-4 pr-8"
      v-for="(record, idx) in contractUsage.rights"
      :key="idx"
    >
      <VMUsageTableItem 
        :record="record" 
        :facilityName="contractUsage.vmRoomType.facility.name"
        :isTentative="contractUsage.tentative"
        @needsReload="handleReload"
      />
    </v-card>
  </div>
</template>

<script>
import VMUsageTableItem from './VMUsageTableItem.vue'

export default {
  components: {VMUsageTableItem},
  props: {
    contractUsage: {Object}
  },
  methods: {
    handleReload(payload) {
      this.$emit('needsReload', {...payload})
    }
  }
}
</script>